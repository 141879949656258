/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: medicine.js
Description: This file has the model,functions  of medicine object used in the pondlogs customer site
*/
export default class Medicine {
  constructor() {
    this.available_stock = 0;
    this.last_purchased_quantity = 0;
    this.last_updated_date = "";
    this.no_of_items = undefined;
    this.per_item_size = undefined;
    this.resource_id = "";
    this.resource_name = "";
    this.total_price = 0;
    this.total_stock = 0;
    this.units = "";
    this.vendor_id = "";
    this.type = "MEDICINE";
  }
}
